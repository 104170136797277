import { useState ,useEffect } from "react";

const useLocationHook = (api) => {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(api);
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchLocations();
    return () => setLocations([]);
  }, [api]);
  return locations;
};



export default useLocationHook
