import React, { useState, useEffect ,useContext } from "react";

import { MdOutlineDeleteForever } from "react-icons/md";
import Loading from "../../Pages/Loading/Loading";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Lib/context/AuthContext/Auth";

//import Img from "https://wallpaperaccess.com/full/6295120.jpg";
import "./Profile.css";

import { auth, db, storage } from "../../Lib/firebase/Firebase"
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import Navbar from "../../Components/Navbar/Navbar";
import { signOut } from "firebase/auth";
import ChatsPage from "../ChatsPage/ChatsPage";


const Img = "https://wallpaperaccess.com/full/6295120.jpg";

const Profile = () => {

  const {user} = useContext(AuthContext);


  const [currentUser ,setCurrentUser] = useState('');

  const [img, setImg] = useState("");
 
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    const docRef = doc(db, "counsellorsList", user.uid);
    getDoc(docRef).then((doc) => {
      setCurrentUser(doc.data());
      
    });
    setLoading(false);
  }, [user.uid]);

  // useEffect(() => {
  //   getDoc(doc(db, "users", user.uid)).then((docSnap) => {
  //     if (docSnap.exists) {
  //       setCurrentUser(docSnap.data());
  //       setLoading(false);
  //     }
  //   });

  //   if (img) {
  //     const uploadImg = async () => {
  //       const imgRef = ref(
  //         storage,
  //         `avatar/${new Date().getTime()} - ${img.name}`
  //       );
  //       try {
  //         if (user.avatarPath) {
  //           await deleteObject(ref(storage, user.avatarPath));
  //         }
  //         const snap = await uploadBytes(imgRef, img);
  //         const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

  //         await updateDoc(doc(db, "users", auth.currentUser.uid), {
  //           avatar: url,
  //           avatarPath: snap.ref.fullPath,
  //         });
  //         setLoading(true);
  //         setImg("");
  //       } catch (err) {
  //         console.log(err.message);
  //       }
  //     };
  //     uploadImg();
  //   }
  // }, [img]);

  const deleteImage = async () => {
    // try {
    //   const confirm = window.confirm("Delete avatar?");

    //   if (confirm) {
    //     await deleteObject(ref(storage, user.avatarPath));

    //     await updateDoc(doc(db, "users", auth.currentUser.uid), {
    //       avatar: "",
    //       avatarPath: "",
    //     });

    //     // history.replace("/");
    //     window.location.reload();
    //     // setLoading(false);
    //   }
    // } catch (err) {
    //   console.log(err.message);
    // }
  };


  console.log(currentUser);

  console.log(user.uid);

  if (loading) {
    return <Loading />;
  } else {
    return currentUser? (
      <>
     
<Navbar/>
      <div className="join_our_team_wrap">
          <div className="container">
            <div className="row">
              <div className="col l12">
                <h3>Profile</h3>
              
              </div>
            </div>
          </div>
        </div>
     
   
      <div className="container">
      <div className="row">
      <div className="col l6">
      <div style={{
        padding:5,
        marginTop:10,
        borderRadius:10,
      }} className='white'>
      
      <h6>Counsellor Profile</h6>
      <p>Hello {currentUser.displayName}</p> 
      <p> Email {currentUser.email}</p> 
      <p>Phone {currentUser.phone}</p> 

     



      </div>
      <div style={{
        padding:5,
        marginTop:10,
        borderRadius:10,
      }} className='white'>
      
    <div 
    style={{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
    }}
    >
    <div>
    <p>Profile Image</p>
    <img className="circle" width={80} src={currentUser.photoURL} alt="" />
    </div>
    
<div>
<button
       onClick={()=>signOut(auth)}
       className="btn btn-small"
       >logout</button>
</div>

      
    </div>

      </div>
      <div style={{
        padding:5,
        marginTop:10,
        borderRadius:10,
      }} className='white'>
      
     


    <h6>Orders And Bookings</h6>
   <p>Patients Orders And Bookings Will Appear Here</p>
      </div>
      
      </div>
      <div className="col l6">
      
      <div>
      <h6>Recents Chats</h6>
      <ChatsPage/>
      
      </div>
      </div>
      
      </div>
      
      
      </div>
       
       

    
      </>
    ) : null;
  }
};

export default Profile;

const Camera = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "25px", height: "25px", cursor: "pointer" }}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const Delete = ({ deleteImage }) => {
  return (
    <svg
      onClick={deleteImage}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "25px",
        height: "25px",
        cursor: "pointer",
        color: "#f24957",
      }}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
};




 
// <div  className="profileWrap">
// <div
//   style={{
//     height: "35vh",
//     position: "relative",
//     // borderRadius: "20px",
//     background: "#094c59",
//     // borderTopRightRadius: "40px",
//   }}
// >
//   <div
//     style={{
//       position: "absolute",
//       background: "white",
//       height: "20vh",
//       width: "20%",
//       left: "65%",
//       top: "-20%",
//       right: "0",
//       borderRadius: 20,
//     }}
//   >
//     <div
//       style={{
//         padding: "1rem",
//       }}
//       className="center "
//     >
//       <img
//         style={{
//           borderRadius: "20%",
//           objectFit: "contain",
//         }}
//         width={"130px"}
//         src={user.avatar || Img}
//         alt="avatar"
//         className="responsive-img"
//       />

//       <div className="container">
//         <div className="row">
//           <div className="col l6 s6">
//             <label htmlFor="photo">
//               <Camera />
//             </label>
//             <input
//               placeholder="update photo"
//               type="file"
//               accept="image/*"
//               style={{ display: "none" }}
//               id="photo"
//               onChange={(e) => setImg(e.target.files[0])}
//             />
//           </div>
//           <div className="col l6 s6">
//             {user.avatar ? (
//               <Delete deleteImage={deleteImage} />
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>

//   <div
//     style={{
//       paddingTop: "3%",
//     }}
//   >
//     <div className="container">
//       <div className="gpt3__header-content">
//         <h4 className=" ">Your Ad Dashboard</h4>
//         <p>keep track of your Ads On bantumart</p>

//         <div className="text_container">
//           <p> Email :{user.email}</p>
//           <hr />
//           <small className="white-text">
//             Joined on: {/*user.createdAt.toDate().toDateString()*/}
//           </small>
//         </div>
//         <br />
//         <Link className="btn btn-small red" to="/post_ad">
//           Post Ad Now
//         </Link>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
// <div className="white">

// </div>
