import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./LoginPage.css";

import { auth, db } from "../../Lib/firebase/Firebase";
import { doc, updateDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";

function LoginPage() {
  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
    loading: false,
  });

  let navigate = useNavigate();

  const { email, password, error, loading } = data;

  const [clicked, setClicked] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setClicked(true);
    e.preventDefault();
    setData({ ...data, error: null, loading: true });
    if (!email || !password) {
      setData({ ...data, error: "All fields are required" });
    }
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await updateDoc(doc(db, "counsellorsList", result.user.uid), {
        isOnline: true,
      });
      setData({
        email: "",
        password: "",
        error: null,
        loading: false,
      });
      navigate("/profile", { replace: true });
    } catch (err) {
      setData({ ...data, error: err.message, loading: false });
    }
  };
  return (
    <div
      style={{
        // paddingBottom: "100px",
        // background:"rgb(0, 102, 148)"
      }}
    
    >
      <div >
        <div style={{
          height:"100vh",
          background: "rgb(6, 16, 39)",
        }} className="container-full ">
          <div className="row">
           
            <div className="col l4 s12 ">
              <div
              
              style={{
                height:"100vh",
                
              }} 
              className="white loginForm_wrap">
                <p style={{
                  marginBottom:30
                }} className="center title">Counsellor Login</p>
                {error ? <p className="error red-text">{error}</p> : null}
                <form className="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined ">
                        <input
                          name="email"
                          onChange={handleChange}
                          id="user_email"
                          type="text"
                        />
                        <label htmlFor="user_email">User Email</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div
                        style={{
                          position: "relative",
                        }}
                        className="input-field input-outlined "
                      >
                        <input
                          name="password"
                          onChange={handleChange}
                          id="user_password"
                          type={visiblePassword ? "text" : "password"}
                        />
                        <small
                          style={{
                            position: "absolute",
                            right: 5,
                            top: "30%",
                            zIndex: 1,
                          }}
                        >
                          {visiblePassword ? (
                            <AiFillEye
                              onClick={() => setVisiblePassword(false)}
                              size={20}
                            />
                          ) : (
                            <AiFillEyeInvisible
                              size={20}
                              onClick={() => setVisiblePassword(true)}
                            />
                          )}
                        </small>
                        <label htmlFor="user_password">User Password</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col l12" />

                    <button
                      style={{
                        height: "45px",
                        background: "rgb(6, 16, 39)",
                        width: "80%",
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                      className="valign-wrapper btn "
                      disabled={loading}
                    >
                      {clicked ? (
                        <>
                          <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-green-only">
                              <div className="circle-clipper left">
                                <div className="circle"></div>
                              </div>
                              <div className="gap-patch">
                                <div className="circle"></div>
                              </div>
                              <div className="circle-clipper right">
                                <div className="circle"></div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>

                  <div className="row">
                    <div>
                      <p>
                        Forgot your password? <Link to="/reset">Reset</Link>
                      </p>

                      <p>
                        Don't have an account yet?{" "}
                        <Link to="/sign_up">Sign up!</Link>
                      </p>
                      <p className="center">
                        <Link to="/">Home</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col l8 hide-on-med-and-down">
            
            <div style={{
              paddingTop:"20vh"
            }}> 
            
            <h5 style={{
              color:"coral"
            }} className=" center">Counsellor Portal</h5>
             <p className="center">
             <img src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/counsellling%20room%2FThe%20Little%20Things%20-%20Working.png?alt=media&token=325be4b6-74b4-4d9c-85a0-1f1c6d402871" alt="" />  
             </p>
            
            
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
