
import React ,{useState,useEffect, useContext ,useRef} from 'react';
// import messages from './messages.json'
import { db } from '../../Lib/firebase/Firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { AuthContext } from '../../Lib/context/AuthContext/Auth';

const Messages = ({chatId}) => {

 // console.log(messages);
 const {user} = useContext(AuthContext);

 const [messages, setMessages] = useState([]);
 // const { data } = useContext(ChatContext);

  useEffect(() => {
    const unSub = onSnapshot(doc(db, "chats", chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };
  }, [chatId]);

 // console.log(messages)
 const ref = useRef();

 useEffect(() => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
}, [messages]);
  return (
    <div >
    

{messages.map((message,index)=>{
  // console.log(message);
  return(
    <div
    key={index}
    ref={ref}

    style={{

       alignSelf: message.senderId === user.uid ?  "flex-end" : "flex-start",
       //maxWidth:"70%",
      // backgroundColor:'red',
     //  backgroundColor: message.senderId === user.uid ? 'lightblue' : 'lightgrey',
       textAlign:  message.senderId === user.uid ? "right" : "left",
         marginLeft:  message.senderId === user.uid ? "40%" : "0",
      //   marginRight:  message.senderId === user.uid ? "0" : "10%",
      // margin: 10,
      //  padding: 5,
      borderRadius: 10,
    //  maxWidth:  message.senderId === user.uid ?  "70%" : "-30%",

    
    }}>
      <p
      style={{
        display: "inline-block",
        background:message.senderId === user.uid? "#064757" : "white",
        color:message.senderId === user.uid ? "white" : "#064757",
        padding:"2px 20px",
        borderRadius:"5px",
        //marginBottom:10,
        fontSize:14,
        marginLeft: message.senderId === user.uid ? "200px" : "0",
        marginRight: message.senderId === user.uid ? "0" : "10%",
    maxWidth:  message.senderId === user.uid ?  "300px" : "300px",
      }}
      
      >{message.text} </p>
    </div>
  )

})}    
    </div>
  )
}

export default Messages






