import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

function ContactPage() {
  return (
    <div>
    <Navbar/>
    ContactPage
    <Footer/>
    
    </div>
  )
}

export default ContactPage
