import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import AboutPage from "./Pages/AboutPage/AboutPage";
import PrivacyPolicy from "./Pages/Business/PrivacyPolicy/PrivacyPolicy";
import HomePage from "./Pages/HomePage/HomePage";
import JoinOurTeamPage from "./Pages/JoinOurTeamPage/JoinOurTeamPage";
import ServicePage from "./Pages/ServicePage/ServicePage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import ScrollTop from "./Lib/ScrollTop/ScrollTop";
import StepOne from "./Pages/StepOne/StepOne";
import PrivateRoute from "./Lib/PrivateRoute/PrivateRoute";
import StepTwo from "./Pages/StepTwo/StepTwo";
import Profile from "./Pages/Profile/Profile";
import ChatsPage from "./Pages/ChatsPage/ChatsPage";
import SingleChatPage from "./Pages/SingleChatPage/SingleChatPage";

function App() {
  return (
    <>
   <ScrollTop/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/join_our_term" element={<JoinOurTeamPage />} />
        <Route path="/our_services" element={<ServicePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />

        <Route path="/sign_in" element={<LoginPage />} />
        <Route path="/sign_up" element={<RegisterPage />} />
        <Route path="/contact" element={<ContactPage/>} />

        <Route path="/step_one" element={<PrivateRoute/>} >
        <Route path="/step_one" element={<StepOne/>} />
        </Route>
        <Route path="/step_two/:id" element={<PrivateRoute/>} >
        <Route path="/step_two/:id" element={<StepTwo/>} />
        </Route>
        <Route path="/chats" element={<PrivateRoute/>} >
        <Route path="/chats" element={<ChatsPage/>} />
        </Route>
        <Route path="/single_chat/:combinedId/:userId" element={<PrivateRoute/>} >
        <Route path="/single_chat/:combinedId/:userId" element={<SingleChatPage/>} />
        </Route>
        <Route path="/profile" element={<PrivateRoute/>} >
        <Route path="/profile" element={<Profile/>} />
        </Route>

      </Routes>
    
    </>
  );
}

export default App;
