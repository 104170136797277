import React, { useState } from "react";
import Add from "../../Lib/images/icons/addAvatar.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db, storage } from "../../Lib/firebase/Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import './RegisterPage.css'

const RegisterPage = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const phone = e.target[1].value;
    const email = e.target[2].value;
    const password = e.target[3].value;
    const file = e.target[4].files[0];

    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      //Create a unique image name
      const date = new Date().getTime();
      const storageRef = ref(storage, `profiles/${displayName + date}`);

      await uploadBytesResumable(storageRef, file).then(() => {
        getDownloadURL(storageRef).then(async (downloadURL) => {
          try {
            //Update profile
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            //create user on firestore
            await setDoc(doc(db, "counsellorsList", res.user.uid), {
              uid: res.user.uid,
              displayName,
              phone,
              email,
              active:false,
              photoURL: downloadURL,
            });

            
       navigate("/step_one");
          } catch (err) {
            console.log(err);
            setErr(true);
            setLoading(false);
          }
        });
      });
    } catch (err) {
      setErr(true);
      setLoading(false);
    }
  };

  return (
    <div >
      <div
      style={{
        height:"100vh",
        background: "rgb(6, 16, 39)",
      }}
      className="container-full">

      <div className="row">
 
      <div className="col l4">
      <div style={{
        height:"100vh",
       
      }} className="white registerForm_wrap">
    
      <p style={{
        marginBottom:30
      }} className="title">Get Into Counselling Room</p>
      <form onSubmit={handleSubmit}>
     



                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined ">
                        <input
                          name="full_name"
                         // onChange={handleChange}
                          id="full_name"
                          type="text"
                        />
                        <label htmlFor="full_name">Full Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined ">
                        <input
                          name="phone"
                         // onChange={handleChange}
                          id="phone"
                          type="text"
                        />
                        <label htmlFor="phone">Phone Number</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined ">
                        <input
                          name="email"
                          type='email'
                         // onChange={handleChange}
                          id="email"
                          
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col l12 s12 center">
                      <div className="input-field input-outlined ">
                        <input
                          name="password"
                          type='password'
                         // onChange={handleChange}
                          id="password"
                          
                        />
                        <label htmlFor="password">Password</label>
                      </div>
                    </div>
                  </div>
        <input required style={{ display: "none" }} type="file" id="file" />

        <div
        
        style={{
          marginTop:30,
          marginBottom:30
        }}>
        
        <label style={{
          marginTop:10,
        }} htmlFor="file">
          <img width={20} src={Add} alt="" />
          <span style={{
            fontSize:25,
          }}>Passport Photo</span>
        </label>
        </div>
        
        <button
        style={{
          height: "45px",
          background: "rgb(6, 16, 39)",
          width: "80%",
          marginLeft: "10%",
          marginRight: "10%",
          borderRadius:10
        }}
        className="valign-wrapper btn "
        disabled={loading}>Sign up</button>
        {loading && "Uploading and compressing the image please wait..."}
        {err && <span>Something went wrong</span>}
      </form>
      <p>
        You do have an account? <Link to="/sign_in">Login</Link>
      </p>
      </div>
      </div>
      <div className="col l8 hide-on-med-and-down">
            
      <div style={{
        paddingTop:"20vh"
      }}> 
      
      <h5 style={{
        color:"coral"
      }} className=" center">Counsellor Portal</h5>
       <p className="center">
       <img src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/counsellling%20room%2FBrazuca%20-%20Date%20Night.png?alt=media&token=860fc150-e8b8-4bdd-862b-0e330575c2dc" alt="" />  
       </p>
      
      
      </div>
      </div>
      
      </div>
       
      </div>
    </div>
  );
};

export default RegisterPage;
