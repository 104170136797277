import React from "react";
import { Link } from "react-router-dom";
import "./Showcase.css";

function Showcase() {
  return (
    <div className="lb_sliderBanner">
      <div className="container">
        <div className="row">
          <div className="col l7">
            <div className="lb_slide_content">
              <h3 className="white-text">POSITIVE CHANGE STARTS WITH YOU</h3>

              <p>
                Therapy is simplified with a video call, messaging and a phone
                call. Start therapy today with a certified therapist today with
                just a click anywhere at any time.
              </p>

              <div className="lb_book_btn">
                <Link
                  to="/contact"
                  className="nav-links btn btn-large white-text indigo darken-4 z-depth-3 hide-on-small-only
"
                >
                  Book Session
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Showcase;
