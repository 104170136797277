import React from "react";
import "./partners.css";

function Partners() {
  return (
    <div className="white">
      <h3
        style={{
          paddingTop: "20px",
        }}
        className="center"
      >
        We work with trusted brands only
      </h3>

      <>
        <div className="lb_lr_slider">
          <div className="slide-track">
            <div className="slide">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/levbitz_partners%2F1.png?alt=media&token=206426ab-0284-4bea-b341-046facb97963"
                height="100"
                width="250"
                alt=""
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/levbitz_partners%2F1.png?alt=media&token=206426ab-0284-4bea-b341-046facb97963"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/levbitz_partners%2F2.png?alt=media&token=c3b0c160-9e93-44cd-a8df-5f5d69e9cbd7"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/levbitz_partners%2F1.png?alt=media&token=206426ab-0284-4bea-b341-046facb97963"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/levbitz_partners%2F2.png?alt=media&token=c3b0c160-9e93-44cd-a8df-5f5d69e9cbd7"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
            <div className="slide">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png"
                height="100"
                width="250"
                alt=""
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Partners;
