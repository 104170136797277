import React from "react";
import Benefits from "../../Components/HomeComponents/Benefits/Benefits";
import DirectMessage from "../../Components/HomeComponents/DirectorMessage/DirectMessage";
import GetReady from "../../Components/HomeComponents/GetReady/GetReady";
import HowWeWork from "../../Components/HomeComponents/HowWeWork/HowWeWork";
import Partners from "../../Components/HomeComponents/Partners/Partners";
import Showcase from "../../Components/Showcase/Showcase";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function HomePage() {
  return (
    <div>
    <Navbar/>
      <Showcase />
      <HowWeWork />
      <Benefits />
      <GetReady />
      <Partners />
      <DirectMessage />

      <div
        style={{
          background: "rgb(6, 16, 39)",
          color: "#fff",
          padding: "1rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col l12 center">
              <h3 >Follow us on Our Social Media Platforms?</h3>
              <p className="white-text">
                We offer comprehensive online mental health treatment options to
                meet all your needs.
              </p>

              <p className="center social_icon_wrap">
                <span>
                  <a
                    href="https://www.facebook.com/profile.php?id=100064091080682"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsFacebook color="#fff" size={28} />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.facebook.com/profile.php?id=100064091080682"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillTwitterCircle color="#fff" size={30} />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.youtube.com/@counsellingroom"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsYoutube color="#fff" size={30} />
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default HomePage;
