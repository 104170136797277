import React, { useState, useEffect } from "react";
import { db, storage } from "../../Lib/firebase/Firebase";
import {  useNavigate } from "react-router-dom";
import './StepTwo.css'

import {
 
  arrayUnion,
 
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useParams } from "react-router-dom";


function StepTwo() {

  const {id} =  useParams();

  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);

  const [myPreview, setMyPreview] = useState([]);
  //const [identifier, setIdentifier] = useState([]);
  const [activation, setActivation] = useState(false);


  const onChangeHandler = async (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const newImage = event.target.files[i];
      newImage["id"] = Math.random();
      setImages((prevState) => [...prevState, newImage]);
    }

    const filesArray = Array.from(event.target.files).map((file, index) =>
      URL.createObjectURL(file, index)
    );

    setMyPreview((prevImages) => prevImages.concat(filesArray));
    Array.from(event.target.files).map(
      (file, index) => URL.revokeObjectURL(file, index) // avoid memory leak
    );
  };

  //console.log(images.length);
  //console.log(myPreview);

  const handleUpload = async () => {
    setUploading(true);
    
    await Promise.all(
      images.map(async (file) => {
        const fileRef = ref(storage, `photos/${id}/${file.name}`);
        const uploadTask = uploadBytes(fileRef, file, "data_url").then(
          async () => {
            const downloadURL = await getDownloadURL(fileRef);

            await updateDoc(doc(db, "counsellorsData", id), {
              images: arrayUnion(downloadURL),
            });
            // await setIdentifier(docRef.id);
            await setActivation(true);
            await setUploading(false);
            await localStorage.removeItem("data");
            await navigate(`/profile/`);
          }
        );
      })
    );
  };

  const renderPhotos = (source) => {
    if (source != null) {
      return source.map((photo, index) => {
        // console.log(index);
        return (
          <div className="col l2 s6" key={index}>
            <p>{photo.id}</p>

            <img
              width={"100%"}
              style={{
                objectFit: "contain",
              }}
              className="responsive-image"
              src={photo}
              alt=""
            />

            {/*<button
              onClick={() => {
                //delete and update fileList
                setImages((prevState) =>
                  prevState.filter((item) => item.id !== photo.id)
                );
                setMyPreview((prevState) =>
                  prevState.filter((item) => item !== photo)
                );
              }}
            >
              delete
            </button>*/}
          </div>
        );
      });
    } else {
      return null;
    }
  };

 

  useEffect(() => {
    setImages(images);
    setMyPreview(myPreview);
  }, [images, myPreview]);
  return (
    <>

      <div >
        <div className="container">
          <div
            style={{
              marginTop: "50px",
             
            }}
            className="row"
          >

          <h3>Please Uplaad Your National Id And Academic Certficates</h3>
            <div className="col l4 s12">
              <div className=" center">
                <label className="round_upload"  htmlFor="upload">
                
                {/*<span>
                <i class="material-icons">add</i> 
          </span>*/}
                
                <span>
                 Upload Documents
                </span> 
                </label>
                <input
                  className="btn"
                  type="file"
                  name="upload"
                  id="upload"
                  style={{ display: "none" }}
                  placeholder="hffhfhhf"
                  multiple
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            <div className="col l8 s12">
              <div>
                <small className="right red-text ak">
                  {" "}
                  *minimum should be 3 images and maximum should be 5 images
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              style={{
                padding: "10px",
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
                width: "100%",
                height: "300px",
                marginTop: "10px",
              }}
            >
              <div className="result">{renderPhotos(myPreview)}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="container ">
            <div className="row">
              <div className="col l4">
                {images.length > 2 ? (
                  activation ? null : (
                    <div onClick={handleUpload}>
                      {uploading ? (
                        <>
                          <button
                            className="btn waves-effect waves-light"
                            type="submit"
                            name="action"
                          >
                            uploading...
                            <i className="material-icons right">
                              <div className="preloader-wrapper small active">
                                <div className="spinner-layer spinner-red-only">
                                  <div className="circle-clipper left">
                                    <div className="circle"></div>
                                  </div>
                                  <div className="gap-patch">
                                    <div className="circle"></div>
                                  </div>
                                  <div className="circle-clipper right">
                                    <div className="circle"></div>
                                  </div>
                                </div>
                              </div>
                            </i>
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn">continue</button>
                        </>
                      )}
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>


          <div style={{
            marginBottom:150
          }}></div>

        </div>
      </div>
    </>
  );
}



export default StepTwo
