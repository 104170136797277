
import  {useParams} from 'react-router-dom'
import Messages from '../../Components/Messages/Messages'
import React, { useRef, useEffect, useContext, useState } from 'react';
import { db } from '../../Lib/firebase/Firebase';
import { AuthContext } from '../../Lib/context/AuthContext/Auth';
import { doc, updateDoc, arrayUnion, serverTimestamp, Timestamp } from 'firebase/firestore';
import Navbar from '../../Components/Navbar/Navbar';




function SingleChatPage() {
  console.log(useParams());
  const {combinedId , userId}  = useParams()
  const {user} = useContext(AuthContext)
  const [text, setText] = useState("");

  const handleSend = async () => {

    console.log(text);
    // if (img) {
    //   const storageRef = ref(storage, uuid());

    //   const uploadTask = uploadBytesResumable(storageRef, img);

    //   uploadTask.on(
    //     (error) => {
    //       //TODO:Handle Error
    //     },
    //     () => {
    //       getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //         await updateDoc(doc(db, "chats", chatId), {
    //           messages: arrayUnion({
    //             id: uuid(),
    //             text,
    //             senderId: currentUser.uid,
    //             date: Timestamp.now(),
    //             img: downloadURL,
    //           }),
    //         });
    //       });
    //     }
    //   );
    // } else {
//if image is present for later

      await updateDoc(doc(db, "chats", combinedId), {
        createdAt: serverTimestamp(),
        messages: arrayUnion({
          // id: uuid(),
          text,
          senderId: user.uid,
          reciverId:userId,
          date: Timestamp.now(),
        }),
      });


  // //  }

  //   // await updateDoc(doc(db, "userChats", currentUser.uid), {
  //   //   senderUid:currentUser.uid,
  //   //   timesent:serverTimestamp(),
  //   //   chatId,
  //   //   senderId:currentUser.uid,
  //   //   recieverId:partyId,
  //   //   lastMessage:text,
  //   //   [chatId + ".lastMessage"]: {
  //   //     text,
  //   //   },
  //   //   [chatId + ".date"]: serverTimestamp(),
  //   // });

  //   // await updateDoc(doc(db, "userChats", partyId), {
  //   //   senderUid:partyId,
  //   //   timesent:serverTimestamp(),
  //   //   chatId,
  //   //   senderId:currentUser.uid,
  //   //   recieverId:partyId,
  //   //   lastMessage:text,
  //   //   [chatId + ".lastMessage"]: {
  //   //     text,
        
  //   //   },
  //   //   [chatId + ".date"]: serverTimestamp(),
  //   // });

    setText("");
    
  };
  return (
    <div style={{
      background:"rgb(6, 16, 39)",
      paddingBottom:"40px"
    }}>
    <Navbar/>
    <div className='container '>
    <div className="row">
    <div className="col l2"></div>
    <div className="col l8 ">
    <div style={{
      border:"1px solid black",
   padding:10,
   borderRadius:10,
    }}>
    <div style={{
      height:'620px',
      marginTop:60,
      overflowWrap:"break-word",
      whiteSpace:"normal",
    overflowY:"scroll",
    

    

    }}>
    <Messages chatId={combinedId} />
    </div>

    

    <div className='indigo' style={{
      display:"flex",
     
      padding:"5px 10px",
      borderRadius:50,
      height:50
    }}>
    <input
           // onChange={(term) => setText(term)}
           style={{
            borderBottom:'none !important',
       
            color:"#fff"
           }}
            value={text}
              onChange={(e)=>setText(e.target.value)}
              placeholder="Type vva message"
            />
            <button
            style={{
              border:"none",
              background:"red"
            }}
            className="transparent"
            onClick={handleSend} >send</button>
    </div>
    
    
    </div>
    
    </div>
    <div className="col l2"></div>
    </div>
    
    </div>
    </div>
  )
}

export default SingleChatPage
