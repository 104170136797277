

import React, { useContext, useState, useEffect } from 'react';
import { Query, or } from 'firebase/firestore';
import { db } from '../../Lib/firebase/Firebase';


import { Link } from 'react-router-dom';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import NoChats from '../../Components/NoChats/NoChats';
import ChatHighLight from '../../Components/ChatHighLight/ChatHighLight';

import { AuthContext } from '../../Lib/context/AuthContext/Auth';
import Navbar from '../../Components/Navbar/Navbar';




const ChatsPage = () => {
 // const { user } = useConp(AuthConp);
 const  {user} =  useContext(AuthContext)
  const [loading, setLoading] = useState(true);


  const [chats, setChats] = useState([]);

  const checckerRef = collection(db, "chats");
  const q = query(
    checckerRef,
    or(where("initiator", "==", user.uid), where("responder", "==", user.uid)),
    orderBy("createdAt", "desc")
  );

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedResult = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setChats(updatedResult);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

console.log(chats);
  return (
    <>
{loading ? (
        <div style={{flex:1,
          justifyContent:"center",
          alignItems:"center",
          marginTop:50,
          paddingHorizontal:10
        }}>
        <p style={{pAlign:"center", color:"#064757"}}>Loading...</p>
       
        {/*<ActivityIndicator size="large" color="#064757" />*/}
        </div>
      ) : (
        chats.length > 0 ? (chats.map((chat) => {
          const lastMessage = chat.data.messages[chat.data.messages.length - 1]?.text;

          let mover = "";
          if (chat.data.messages[chat.data.messages.length - 1]?.senderId === user.uid) {
            mover = chat.data.messages[chat.data.messages.length - 1]?.reciverId;
          } else {
            mover = chat.data.messages[chat.data.messages.length - 1]?.senderId;
          }

          console.log(mover);
          console.log(lastMessage);
         // alert(lastMessage)
          return (
            <Link 
            to={`/single_chat/${chat.id}/${mover}`}
            key={chat.id}
          //    onPress={() =>
          //     // navigation.navigate("single chart")

          //   //  navigation.navigate('single chat' ,{
          //   //     combinedId:chat.id,
          //   //     userId:mover})

          //  navigation.navigate('Chats' ,{
          //     screen:"single_chat",
          //     params:{
          //       // combinedId:combinedId,
          //       // userId:userId,
          //            combinedId:chat.id,
          //       userId:mover
          //     },
          //     //   combinedId:combinedId,
          //     // userId:userId
          //    })
              
          //     }
             
             
             >
              
              <ChatHighLight mover={mover} chat={chat} >
              {lastMessage}
              </ChatHighLight>
            </Link >
          );
        })): <NoChats/>
      )}

   
      
  
    </>
  );
};

export default ChatsPage;




// import React, { useContext, useState, useEffect } from 'react';
// import { Query, or } from 'firebase/firestore';
// import { db } from '../../Lib/firebase/Firebase';


// import { Link } from 'react-router-dom';
// import {
//   collection,
//   query,
//   where,
//   onSnapshot,
//   orderBy,
//   limit,
// } from "firebase/firestore";
// import NoChats from '../../Components/NoChats/NoChats';
// import ChatHighLight from '../../Components/ChatHighLight/ChatHighLight';

// import { AuthContext } from '../../Lib/context/AuthContext/Auth';
// import Navbar from '../../Components/Navbar/Navbar';




// const ChatsPage = () => {
//  // const { user } = useConp(AuthConp);
//  const  {user} =  useContext(AuthContext)
//   const [loading, setLoading] = useState(true);


//   const [chats, setChats] = useState([]);

//   const checckerRef = collection(db, "chats");
//   const q = query(
//     checckerRef,
//     or(where("initiator", "==", user.uid), where("responder", "==", user.uid)),
//     orderBy("createdAt", "desc")
//   );

//   useEffect(() => {
//     setLoading(true);
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const updatedResult = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         data: doc.data(),
//       }));
//       setChats(updatedResult);
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

// console.log(chats);
//   return (
//     <>

//     <div style={{
//       background:"rgb(6, 16, 39)",
//       height:80
//     }}>

//     </div>
//     <div style={{
//       background:"rgb(6, 16, 39)"
//     }}>
//     <Navbar className='red' />
//     </div>
   
//       <div style={{
//         height:80,
//        // backgroundColor:COLORS.mainColor,
//         justifyContent:"center",
//         // alignItems:"center",
//         marginBottom:10,
//         paddingTop:20
      
//       }}>
      
      
     
//       </div>

//       <div className='container'>
//       <div className="row">
//       <div className="col l5">
      
//       <div>
//       {loading ? (
//         <div style={{flex:1,
//           justifyContent:"center",
//           alignItems:"center",
//           marginTop:50,
//           paddingHorizontal:10
//         }}>
//         <p style={{pAlign:"center", color:"#064757"}}>Loading...</p>
       
//         {/*<ActivityIndicator size="large" color="#064757" />*/}
//         </div>
//       ) : (
//         chats.length > 0 ? (chats.map((chat) => {
//           const lastMessage = chat.data.messages[chat.data.messages.length - 1]?.text;

//           let mover = "";
//           if (chat.data.messages[chat.data.messages.length - 1]?.senderId === user.uid) {
//             mover = chat.data.messages[chat.data.messages.length - 1]?.reciverId;
//           } else {
//             mover = chat.data.messages[chat.data.messages.length - 1]?.senderId;
//           }

//           console.log(mover);
//           console.log(lastMessage);
//          // alert(lastMessage)
//           return (
//             <Link 
//             to={`/single_chat/${chat.id}/${mover}`}
//             key={chat.id}
//           //    onPress={() =>
//           //     // navigation.navigate("single chart")

//           //   //  navigation.navigate('single chat' ,{
//           //   //     combinedId:chat.id,
//           //   //     userId:mover})

//           //  navigation.navigate('Chats' ,{
//           //     screen:"single_chat",
//           //     params:{
//           //       // combinedId:combinedId,
//           //       // userId:userId,
//           //            combinedId:chat.id,
//           //       userId:mover
//           //     },
//           //     //   combinedId:combinedId,
//           //     // userId:userId
//           //    })
              
//           //     }
             
             
//              >
              
//               <ChatHighLight mover={mover} chat={chat} >
//               {lastMessage}
//               </ChatHighLight>
//             </Link >
//           );
//         })): <NoChats/>
//       )}

//       </div>
//       </div>
//       </div>
      
//       </div>
//     </>
//   );
// };

// export default ChatsPage;


