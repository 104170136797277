import React from "react";
import AbtImg from "../../Lib/images/abt.png";
import { BsStars } from "react-icons/bs";
import Navbar from '../../Components/Navbar/Navbar'
import Footer from "../../Components/Footer/Footer";

function AboutPage() {
  return (
    <div>
    <Navbar/>
      <div>
        <div className="join_our_team_wrap">
          <div className="container">
            <div className="row">
              <div className="col l12">
                <h3>Our Mission</h3>
                <p className="white-text">
                  We help anyone struggling with life’s challenges access,
                  afford, and conveniently get professional therapy anytime and
                  anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "20px 0px",
          }}
          className="white"
        >
          <div className="container">
            <div className="row">
              <div className="col l4">
                <div
                  style={{
                    marginTop: 30,
                  }}
                >
                  <img className="responsive-img" src={AbtImg} alt="" />
                </div>
              </div>
              <div className="col l8">
                <div>
                  <h5 className="center">Our Culture And Values</h5>
                  <p>
                    We believe that digital mental health solutions will
                    transform mental healthcare in Uganda and Africa at large.
                    Our mission is to help anyone struggling with life’s
                    challenges access, afford, and conveniently get professional
                    therapy anytime and anywhere. We do this by connecting the
                    clients with the best and experienced professionals in the
                    field of psychology to realize a better mental health care
                    experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col l6">
              <div>
                <h5>Client First</h5>
                <h6>
                  We attend to our clients immediately before anything else.
                </h6>

                <p>
                  <span
                    style={{
                      margingRight: 10,
                    }}
                  >
                    <BsStars color="coral" />
                  </span>
                  <span> Understand and support clients without judgment.</span>
                </p>
                <p>
                  <span
                    style={{
                      margingRight: 10,
                    }}
                  >
                    <BsStars color="coral" />
                  </span>
                  <span>Enrich client-provider relationships.</span>
                </p>
              </div>
            </div>
            <div className="col l6">
              <div>
                <h5>Client First</h5>
                <h6>
                  We attend to our clients immediately before anything else.
                </h6>

                <p>
                  <span
                    style={{
                      margingRight: 10,
                    }}
                  >
                    <BsStars color="coral" />
                  </span>
                  <span> Understand and support clients without judgment.</span>
                </p>
                <p>
                  <span
                    style={{
                      margingRight: 10,
                    }}
                  >
                    <BsStars color="coral" />
                  </span>
                  <span>Enrich client-provider relationships.</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "20px 0px",
          }}
        >
          <div className="requirement_wrap"></div>
          <div>
            <div className="container ">
              <div className="row">
                <div className="col l6">
                  <div>
                    <h5 className="center">One Team</h5>
                    <p>
                      We offer quality services to our esteemed clients with
                      passion and love.
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Be informed and speak up.</span>
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Be informed and speak up.</span>
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Hire outstanding professionals.</span>
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>
                        Serve the communities with empathy and unconditionally.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col l6">
                  <div>
                    <h5 className="center">Delivery Services</h5>

                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Empathetic and passionate about what they do.</span>
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Work with agility and sense of urgency.</span>
                    </p>
                    <p>
                      <span
                        style={{
                          margingRight: 10,
                        }}
                      >
                        <BsStars color="coral" />
                      </span>
                      <span>Act with integrity and confidentiality.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default AboutPage;
