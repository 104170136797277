import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./Lib/context/AuthContext/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <AuthProvider>
    <React.StrictMode>
      <App />
     
    </React.StrictMode>
    </AuthProvider>
  </BrowserRouter>
);
