import React from 'react'
import "./Loading.css"

function Loading() {
  return (
    <div className='loading_wrap'>
    
   {/* Loading*/}
    </div>
  )
}

export default Loading
