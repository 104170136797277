import React ,{useState ,useCallback, useContext} from 'react'
import useLocationHook from '../../Lib/Hooks/useLocationHook/useLocationhook'
import Select from "react-select";
import { addDoc, collection, serverTimestamp , doc ,setDoc } from 'firebase/firestore';
import { db } from '../../Lib/firebase/Firebase';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Lib/context/AuthContext/Auth';

function StepOne() {
  const {user} = useContext(AuthContext);



  const navigate = useNavigate();

  
  const api = "https://api.levbitz.com/uganda/locations/locations.json";
  const ugandaLocations = useLocationHook(api);

  console.log(ugandaLocations.length);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleLocationChange = useCallback(
    (selectedOption) => {
     setSelectedLocation(selectedOption.district)
  
  
    },
    []
  );


  const qualificationData  = [
    { value: 'Certificate', label: 'Certificate' }, 
    { value: 'Diploma', label: 'Diploma' },
    { value: 'Degree', label: 'Degree' },
    { value: 'Masters', label: 'Masters' },
    { value: 'Phd', label: 'Phd' },
  ];
  const workingStatusData  = [
    { value: 'yes', label: 'yes' }, 
    { value: 'No', label: 'No' },
   
  ];
  const GenderData  = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
   
  ];

  const [qualification, setQualification] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [institute ,setInstitute] = useState('')
  const [workingStatus, setWorkingStatus] = useState("");
  const [gender ,setGender] = useState("");
  const [writtenCv , setWrittenCv] = useState("");
  const [skills, setSkills] = useState("");
  

  const invalid = skills === "" || writtenCv == "" ||gender === ""

const StepOneHandler =  async () =>{
//  const docRef=  await addDoc(collection(db, "counsellorsData"), {
//     createdAt: serverTimestamp(),
//    qualification,
//    district:selectedLocation,
//    graduationYear,
//    workingStatus,
//    gender,
//    institute,
//    writtenCv,
//    skills,
//    uid:user.uid
//   });


  await setDoc(doc(db, "counsellorsData", user.uid), {
    createdAt: serverTimestamp(),
    qualification,
    district:selectedLocation,
    graduationYear,
    workingStatus,
    gender,
    institute,
    writtenCv,
    skills,
    uid:user.uid
  });
  await navigate(`/step_two/${user.uid}`);
}


  return (
    <div style={{
      paddingTop:50,
    }}>
    
    


    <div className="container">
    
    <div className="row">
  <p>Step 1 of 2</p>

    <div className="col l12">



    <div className="row">
    <div className="col l6 s12">
   <div>
   <div>
   {ugandaLocations.length === 0 ? <p>Loading location Data...</p> : (

 
     <Select
     styles={customStyles}
     placeholder='Choose District'
       onChange={handleLocationChange}
       getOptionLabel={(option) => `${option.district} `}
       getOptionValue={(option) => `${option}`}
       isOptionSelected={(option) => {}}
       options={ugandaLocations}
       isSearchable={true}
     /> 

   )}
   
   </div>
   </div> 
    
    </div>

    <div className="col l6 s12">
   <div>
   <div>
   <Select
    styles={customStyles}
    placeholder='Qualification'
        defaultValue={qualification}
        onChange={(option) => setQualification(option.value)}
       
        options={qualificationData}
      />

   </div>
   </div> 
    
    </div>
    </div>
    
   
{/* second row */}
<div className="row">
<div className="col l6 s12">
<div>
<Select
    styles={customStyles}
    placeholder='Qualification'
        defaultValue={qualification}
        onChange={(option) => setQualification(option.value)}
       
        options={qualificationData}
      />
</div>
</div>
<div className="col l6 s12">
<div>
<Select
    styles={customStyles}
    placeholder='Are Currently Working'
        defaultValue={qualification}
        onChange={ (option) => setWorkingStatus(option.value)}
        options={workingStatusData}
      />
</div>
</div>
</div>

{/* second row */}

{/*third row */}
<div className="row">
<div className="col l6 s12">
<Select
styles={customStyles}
placeholder='Gender'
    defaultValue={gender}
    onChange={ (option) => setGender(option.value)}
    options={GenderData}
  />
</div>

<div className="col l6 s12">
<div className="input-field input-outlined ">
<div style={{
  marginBottom:30
}}/>
<input
  name="graduation"
 // onChange={handleChange}
  id="graduation"
  type="text"
  style={{
    height:55
  }}
  onChange={(e)=>setGraduationYear(e.target.value)}

/>
<label htmlFor="graduation">Graduation Year</label>
</div>

</div>
</div>
{/*third row */}
  
  
   
    <div className='row'>
    <div className="col l12">
    
    <div className="input-field input-outlined ">
<div style={{
  marginBottom:30
}}/>
<input
  name="institute"
 // onChange={handleChange}
  id="institute"
  type="text"
  style={{
    height:55
  }}
  onChange={(e)=>setInstitute(e.target.value)}

/>
<label htmlFor="institute">University/ Institute of Graduation</label>
</div>
    </div>
   
    </div>
   
    
<div style={{
  marginTop:20,
}}>
<textarea
style={{
  height:200,
  borderRadius:5,
  padding:5
}}
onChange={(e)=>setWrittenCv(e.target.value)}
placeholder="Talk about your Experience in not less that 2000  words" name="" id="" cols="30" rows="190"></textarea>
</div>

<div style={{
  marginTop:20,
}}>
<small className='red-text'>Please Separe Every Skill With A Coma</small>
<textarea
style={{
  height:100,
  borderRadius:5,
  padding:5
}}
onChange={(e)=>setSkills(e.target.value)}
placeholder="Skill" name="" id="" cols="30" rows="190"></textarea>
</div>
    
<div style={{
  marginTop:50
}}>
<button
disabled={invalid}
style={{
  background:invalid? "gray":"rgb(6, 16, 39)"
}} className='btn' onClick={StepOneHandler}>Continue</button>
</div>
    </div>
    <div className="col l3">
    
    
    </div>
    </div>
    
    
    </div>
   
 </div>
  )
}

export default StepOne





const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid rgb(6, 16, 39)',
    boxShadow: state.isFocused ? '0 0 0 1px rgb(6, 16, 39)' : null,
    marginTop:10,
   
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgb(6, 16, 39)' : null,
    color: state.isSelected ? 'white' : 'black',
    
  }),
};
