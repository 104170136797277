import React from "react";
import "./GetReady.css";
import { TiMessages } from "react-icons/ti";
import {
  AiFillVideoCamera,
  AiFillAudio,
  AiOutlineUserSwitch,
  AiTwotonePhone,
} from "react-icons/ai";

function GetReady() {
  return (
    <div className="get_started_wrap">
      <div className="container">
        <div className="row">
          <h3 className="center">Ready to get started?</h3>
          {/*<p className="center">
            Get online mental Health treatment options to meet all your needs.
            Video Messaging Audio and Phone call
  </p>*/}
        </div>

        <div className=" center">
          <div className="row">
            <div className="col l3 center"></div>
            <div className="col l8 s12 center">
              <div className="row ">
                <div className="col l6 s12 center">
                  <div>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 10,
                        }}
                      >
                        <TiMessages size={30} />
                      </span>
                      <span>Messaging</span>
                    </p>

                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 10,
                        }}
                      >
                        <AiFillVideoCamera size={30} />
                      </span>
                      <span>Video</span>
                    </p>
                  </div>
                </div>

                <div className="col l6 s12">
                  <div>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 10,
                        }}
                      >
                        <AiTwotonePhone size={30} />
                      </span>
                      <span>Phone Call</span>
                    </p>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          marginRight: 10,
                        }}
                      >
                        <AiFillAudio size={30} />
                      </span>
                      <span>Audio</span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                {/* <span>
                  <AiOutlineUserSwitch size={30} />
                  </span>*/}
              </div>
            </div>
            <div className="col l2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetReady;
