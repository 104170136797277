
import React ,{useEffect ,useState} from 'react'


import { db } from "../../Lib/firebase/Firebase"
import { getDoc, doc } from 'firebase/firestore';
// import { Entypo } from '@expo/vector-icons';






const ChatHighLight = ({children ,mover}) => {

 

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
 // console.log(mover);
 //alert(mover)

  useEffect(() => {
    getDoc(doc(db, "users", mover)).then((docSnap) => {
      if (docSnap.exists) {
        setUser(docSnap.data());
        setLoading(false);
      }
    });

    
  }, [mover]);

  // console.log(user);
  // alert(user)


  if(loading){
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  }else{
    return (
  
      <div
      
      //className='red'
      className='grey lighten-3'
      
       style={{
        flexDirection:'row',
        //justifyContent:'space-between',
        marginBottom:2,
    paddingHorizontal:5,
       display:'flex'
       
        
     
      }}>
      
        <div>
        {/*<Image
        source={{
          uri: "https://firebasestorage.googleapis.com/v0/b/ug-b2b.appspot.com/o/icons%2Fuser.png?alt=media&token=171c3a30-e1d4-4720-a36f-12fac0126b08"
          // uri: user.avatar? user.avatar :"https://firebasestorage.googleapis.com/v0/b/ug-b2b.appspot.com/o/icons%2Fuser.png?alt=media&token=171c3a30-e1d4-4720-a36f-12fac0126b08"
        }}
        style={{width:40,height:40,borderRadius:50}}
      />*/}
      <img 
      style={{width:40,height:40,borderRadius:50}}
      alt="user"
      loading="lazy"
      decoding="async"
      fetchpriority="high"
      sizes="100vw"
      width={40}
      height={40} 
      src="https://firebasestorage.googleapis.com/v0/b/ug-b2b.appspot.com/o/icons%2Fuser.png?alt=media&token=171c3a30-e1d4-4720-a36f-12fac0126b08"
    />
        </div>
        <div 
        style={{
          paddingRight:40,
          marginLeft:20,
          borderBottomWidth:.5,
          borderBottomColor:'#fff',
          paddingVertical:5,
  //width:width*.8
        }}
        >
        <div >
        <div style={{
         justifyContent:'flex-start',
         flexDirection:'row',
         alignItems:'center',
        
        }}>
        
         <p style={{
           fontSize:14,
          //  color:colors[theme].pColor,
         fontWeight:'bold',
         
         }}>{user?.name}    </p>

         {/*user?.isOnline === true ? <Entypo style={{marginTop:2}} name="dot-single" size={24} color="green" /> : null*/}

         
        
         </div>
        <p 
      
  
        style={{
          fontSize:13,
          color:'grey',
        }}
        >
      {children}
        </p>
        </div>
       
        </div>
      </div>
    )
  }

 
}

export default ChatHighLight


