import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <div>
        <div className="join_our_team_wrap">
          <div className="container">
            <div className="row">
              <div className="col l12">
                <h3>Privacy Policy</h3>
                <p className="white-text">
                  Counseling Room takes your privacy seriously, and we want you
                  to know how we collect, use, share and protect your
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col l12">
              <div>
                <h5>Introduction</h5>
                <p>
                  Our platform uses advanced data-driven tools to personalize
                  your care and to enhance evidence of care, care continuity,
                  outcome metrics, and data-based learnings. We provide mental
                  health professionals with insights and measurable clinical
                  outcomes for patients receiving virtual therapeutic services
                  on our platform. You can access our platform via the website
                  or through our mobile application available in the store(app)
                </p>
                <p>
                  When you enroll, inquire or use of Counselling Room Services
                  (as defined below), we keep a record of the medical
                  information that you provide to us or your therapist as well
                  as any medical information that employees or independently
                  contracted therapists provide to you via our platform (such as
                  information on and related to your diagnosis). Medical
                  information means any information that:
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <p>. identifies you; and </p>
                      <p>
                        . relates to your past, present, or future physical or
                        mental health, treatment, the provision of health care
                        services, or payment for treatment. 
                      </p>
                    </div>
                  </div>
                </div>

                <p>
                  This includes medical history, diagnoses, treatments, current
                  medical condition, and use of prescription medications.{" "}
                </p>

                <p>
                  This Policy explains Counselling Room’s practices where we
                  process “personal data”, which is information that relates to
                  an identified or identifiable individual. To “process” or
                  “processing” means the use of personal data including,
                  collecting, recording, storing, using, analyzing, combining,
                  transferring, disclosing, or deleting. 
                </p>

                <p>
                  We reserve the right to change the terms of this Privacy
                  Policy at any time by posting those changes in revisions to
                  this Privacy Policy, so that you are always aware of our
                  processes related to collection, use and disclosure of
                  information. We urge you to check here for any updates to this
                  Privacy Policy from time to time. Unless otherwise indicated,
                  any changes to this Privacy Policy will apply immediately upon
                  posting to the Website or the App.
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <p> • What is included in this Policy?</p>
                      <p> • Personal Data We Process & How We Use It </p>
                      <p> • Using the Counselling App</p>
                      <p>• Advanced Data Processing</p>
                      <p> • Research</p>
                      <p>
                        {" "}
                        • What choices do you have about how we collect or use
                        your information?
                      </p>
                      <p>• Retention</p>
                      <p>• Counselling Room and Minors</p>
                      <p>• Other Mobile Applications and Services </p>
                      <p>• Applying for a Job at Counselling Room</p>
                      <p>• Security </p>
                      <p>• How to Contact Us</p>
                    </div>
                  </div>
                </div>

                <h4>What is included in this Policy? </h4>

                <p>
                  The information on this page applies to the personal data we
                  collect about your interactions, use, and experience with our
                  website at https://www.counsellingroomonline.com (our
                  “Website”), Android (our “App”), and in connection with the
                  online therapy services we provide (the Website, App and our
                  online therapy services are together, the “Services”). As
                  noted below, our data practices depend on how you interact
                  with our Services, and how you receive and pay for Services.
                </p>
                <p>
                  This Policy does not apply to any other websites that you
                  visit before our website or any third-party sites that may be
                  accessible through the Counselling Room Services. Please read
                  this Policy carefully so that you understand your rights in
                  relation to personal data, and how we will process that
                  personal data.
                </p>
                <p>
                  If you do not want us to share personal data or feel
                  uncomfortable with the ways we use information in order to
                  deliver our Services, please do not use the Services.
                </p>

                <h4>Personal Data We Process & How We Use It</h4>
                <p>
                  This article presents the types of information the sources,
                  and the uses. 
                </p>
                <h5>Personal Data Processing</h5>
                <h5>Types of Personal Data</h5>

                <h5>Registration</h5>
                <p>
                  Information you provide when you create an account for
                  yourself (or an authorized account for a minor), are matched
                  to a therapist, or register yourself (or on behalf of a minor)
                  as a patient: 
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          • Name (e.g., your name or the name of your
                          parent/guardian)
                        </p>
                        <p>• Address</p>
                        <p>• Country</p>
                        <p>• Date of birth</p>
                        <p> • Phone number</p>
                        <p> • Gender and the preferred gender of therapist</p>

                        <p>• Email</p>

                        <p> • Relationship status</p>
                        <p>• Organization/Employer (if applicable)</p>
                        <p>• Payment information and transaction history</p>
                        <p>
                          {" "}
                          • Information on the type of subscription you choose
                        </p>
                        <p> • Insurance information </p>

                        <p>• Referral source</p>

                        <p>
                          • Information on why you are accessing our Services
                          including what you are hoping to get from therapy
                        </p>
                        <p> • Notification preferences</p>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>How We Get It</h5>
                <p>You provide this when you go through the intake process</p>

                <h5>What we do with it</h5>

                <p>
                  • Provide you with the Services (including resubscription)
                </p>
                <p>  • Counselling Room team match you to a therapist</p>
                <p> • Provide you with treatment information</p>

                <p>• Enroll you in services and administer your account</p>
                <p>• Provide you with support</p>
                <p>• Process billing, and payment information</p>

                <p>
                  {" "}
                  • Maintaining the safety and security of our users, our
                  Services, and our business
                </p>
                <p>
                  {" "}
                  • Provide announcements and communicate with you, including
                  for marketing purposes
                </p>
                <p>
                  {" "}
                  • Provide mandatory reporting to law enforcement or other
                  governmental authorities, for example in instances of abuse,
                  or ascertainable threats of violence to another person
                </p>

                <p> • Respond to a valid legal request.</p>
                <p> • Process claims or insurance information</p>
                <p>
                  • Provide you with and to evaluate, improve and develop the
                  Services
                </p>

                <p>• Conduct therapist oversight</p>

                <p>• Control the quality of the Services</p>

                <p>
                  {" "}
                  • Permissive reporting of abuse; expressed threats of violence
                  towards an ascertainable victim
                </p>

                <h5>Use of the Services</h5>
                <p>
                  Data you provide when you use our therapy service, including:
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          • Information you disclose in chat data and your chat
                          sharing preferences (transcripts)
                        </p>
                        <p>• Audio/Video communication </p>
                        <p>
                          • Documents you share with your therapist via our chat
                          functionality
                        </p>
                        <p>
                          • Information collected via our symptom tracker and
                          information on your clinical progress
                        </p>

                        <p>
                          • Information collected via chat, telephone, or email
                          support channels
                        </p>

                        <p> • Messages that you “star”</p>
                        <p>• Information on friends you refer</p>
                        <p>
                          • Information you provide as part of treatment intake
                          including emergency contact details, information on
                          your health and mental health and medical history
                        </p>
                        <p>
                          {" "}
                          • If you use couples therapy, sharing of contact
                          details and some communications will be conducted
                          jointly.
                        </p>
                        <p>
                          • If you choose to admit another individual to a
                          therapy “room” for a session, their contact
                          information will be collected and used for that
                          purpose.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Through your use of the Services</h5>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <p> • To provide you with the Services</p>

                      <p>
                        • To build, modify, and develop new products, features,
                        and Services.
                      </p>

                      <p>
                        {" "}
                        • To conduct clinical and other academic research,
                        internally and with approved research partners and
                        identify summary trends or insights for use in external
                        communications (where direct identifiers such as name
                        and contact details have been removed, or pursuant to
                        explicit patient authorization). For more information
                        see “Research” below.
                      </p>

                      <p>• To address patient concerns or complaints</p>

                      <p>
                        {" "}
                        • To carry out quality assurance and compliance
                        activities
                      </p>

                      <p>
                        • To provide you with assistance in the event of an
                        emergency
                      </p>
                    </div>
                  </div>
                </div>

                <h5>When Website visitors contact us</h5>

                <p>
                  We collect information when you communicate with us via email.
                  This includes information that you provide when you contact us
                  as well as your email address.
                </p>
                <p>You provide this information to us directly</p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          • To respond and address your communication • To
                          provide you with the Services
                        </p>
                        <p> • To improve the Services</p>
                        <p>
                          • Provide support to users (therapists and patients)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Technical Data </h5>

                <p>
                  Technical information from software or systems hosting the
                  Services, and from the systems, applications and devices that
                  are used to access the Services, such as: 
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          {" "}
                          • Information on the device operating system or
                          Counselling Room environment 
                        </p>
                        <p>• Metrics on system or App feature use </p>
                      </div>
                    </div>
                  </div>
                </div>

                <p>Information on system events and status</p>
                <p>Automatically through use of the Services</p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          {" "}
                          • Create anonymized and/or aggregated data to improve
                          and deliver our Services 
                        </p>
                        <p>• Comply with legal obligations</p>
                        <p>• Maintain the security of our infrastructure</p>

                        <p>
                          • Facilitate the delivery and optimization of Services
                        </p>

                        <p>
                          {" "}
                          • Monitor performance of our data centers and
                          networks, systems and applications
                        </p>

                        <p>
                          • Provide support to users (therapists and patients)
                        </p>

                        <p>
                          {" "}
                          • Administer our business continuity and disaster
                          recovery plans and policies
                        </p>

                        <p>
                          • Detect, investigate, and remediate stop fraudulent,
                          harmful, unauthorized, or illegal activity (“fraud and
                          abuse detection”) through automated and manual means 
                        </p>
                        <p>
                          • To provide you with and to evaluate, improve and
                          develop the Services
                        </p>
                        <p>• To develop new products</p>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Persistent Identifiers </h5>
                <p>
                  Data collected via cookies, pixels and other tracking
                  technologies (such as Google Analytics and Google Ads), such
                  as:
                </p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>• Internet protocol (IP) addresses</p>
                        <p> • Device ID</p>
                        <p>• Browser type</p>
                        <p>• Internet service provider (ISP)</p>
                        <p> • Referrer URL</p>
                        <p>
                          • Geolocation information (derived from IP Address,
                        </p>
                        <p>
                          • Exit pages, the pages and files viewed on our
                          Website (e.g., HTML pages, graphics, etc.) 
                        </p>
                        <p>• Operating system </p>
                        <p>• Date/time stamp</p>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  NOTE: Once you become a patient the use of cookies, web
                  beacons, pixels, and other processes are limited to ensure
                  that we do not monitor or use patient information for other
                  purposes without your consent.
                </p>
                <p>Collected automatically when you use the Services</p>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          {" "}
                          • To provide you with and to evaluate, improve and
                          develop the Services
                        </p>
                        <p> • To develop new products</p>

                        <p>
                          • Analyze how our Services are used so we can improve
                          your experience
                        </p>
                        <p>• Evaluate the success of our marketing campaigns</p>
                        <p> • Marketing, including tailoring advertising</p>
                      </div>
                    </div>
                  </div>
                </div>

                <h5>Using the Counselling Room App</h5>

                <p>
                  The Counselling Room App includes third party software
                  development kits (SDKs) from a number of other companies whom
                  we engage as service providers, for identifying and logging
                  code issues, errors and events; managing interactive
                  communication within the App; compiling analytics on which
                  features get used the most; facilitating customer service
                  contacts regarding subscriptions and service; and processing
                  device identifiers and event logs for ad attribution purposes
                  (such as the initial login event, account creation,
                  subscription events). These third parties process data as a
                  service to Counselling Room only, pursuant to written
                  agreements. Counselling does not sell client information to
                  third parties.
                </p>

                <h5>Advanced Data Processing</h5>

                <p>
                  The Counselling Room therapy experience is enhanced by
                  advanced data processing activities, carried out in order to
                  measure and improve clinical outcomes. Our proprietary
                  matching algorithm and machine-learning tools provide
                  real-time engagement insights, inform treatment, and track
                  clinical progress, and are fundamental to our precision
                  therapy care delivery model. 
                </p>

                <p>
                  <b>Matching Algorithm.</b> During onboarding we ask you to
                  provide information so that we can assess your condition and
                  incorporate your preferences. We then leverage a proprietary
                  algorithm (and/or support from a Counselling Room consultant)
                  to match you to a provider. 
                </p>

                <p>
                  <b> Optimizing Diagnosis and Treatment.</b> Throughout your
                  experience, your provider uses the Counselling Room Services
                  to manage your diagnosis and treatment plan. The advanced
                  machine learning features of our proprietary Services include
                  natural language processing of communications with therapists.
                  A core focus of our machine learning strategy is to provide
                  the therapist with insights on patient needs and behaviors and
                  offer techniques and suggestions that we believe are likely to
                  maximize clinical outcomes.
                </p>
                <h5>Research</h5>
                <p>
                  Separate and apart from internal healthcare operations and
                  quality assurance activities undertaken for the purpose of
                  delivering and optimizing patient care on the platform, from
                  time to time, Counselling Room conducts or participates in
                  research studies with select universities. You may be offered
                  the opportunity to participate in these studies. If your
                  protected health information will be disclosed as part of
                  these studies, your information will only be included with
                  your written authorization. You will be asked to review and
                  accept terms and enrollment separately from the information
                  included in this Policy. Information from these studies may be
                  published by third parties including through various media
                  platforms/academic journals.{" "}
                </p>
                <h5>
                  What choices do you have about how we collect or use your
                  information?
                </h5>

                <p>
                  When you create a Counselling Room account or otherwise use
                  the Counselling Room Services, we collect (or you may provide
                  us with) your email address, and you have the choice to
                  receive newsletters or promotional material about our services
                  at this address. These messages may be tailored to your
                  responses to onboarding surveys so that you receive
                  information relevant to you. You can further manage your email
                  preferences by unsubscribing from emails (via the link in the
                  message), emailing <b>counsellingroomonline@gmail.com</b> or
                  by visiting our online preference center within the App or the
                  Website. 
                </p>

                <h6>
                  In addition to your rights as a patient, you can ask us to:
                </h6>

                <div className="container">
                  <div className="row">
                    <div className="col l12">
                      <div>
                        <p>
                          {" "}
                          • Stop sending marketing or promotional emails and
                          mobile marketing communications from Counselling Room.
                        </p>
                        <p>
                          • Limit the use of cookies, pixels, or web beacons. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <h6>
                  To ask us about these choices,
                  contact cousellingroomonline@gmail.com.
                </h6>

                <h5>Retention</h5>
                <p>
                  We will retain your information in accordance with the
                  appropriate statutory limitation periods as required by local
                  law, in line with our legitimate business purposes for as long
                  as your account is active or for as long as needed to provide
                  you with the Services, as required in order to comply with our
                  legal obligations, a court order or to defend or pursue legal
                  claims, in line with industry codes of practice, to resolve
                  disputes and enforce our agreements. 
                </p>

                <h5>Counselling Room and Minors</h5>
                <p>
                  In the United States, Counselling Room may collect information
                  and may provide Services to minors ages 13 – 17 with the
                  written authorization of a parent or guardian.
                </p>

                <h5>Other Mobile Applications and Services</h5>

                <p>
                  This Policy does not apply to any third-party applications or
                  software that you download, or any other third-party websites,
                  mobile applications, or online products, services, or
                  businesses you may access from the Counselling Room Website or
                  App. 
                </p>

                <h5>Security</h5>
                <p>
                  We take commercially reasonable steps to protect the integrity
                  and confidentiality of personally identifiable and health
                  information that you may share with us. We have complied with
                  the HIPAA security rule for administrative, technical, and
                  physical security safeguards and have third party assessments
                  of our controls performed annually. However, please be aware
                  that no security measures are perfect or impenetrable and we
                  cannot guarantee the absolute security of your information. 
                </p>

                <p>
                  We will do our part to protect your information, but it is
                  important for you to protect your information as well. In
                  addition, we do not control the actions of anyone with whom
                  you or any other Counselling Room user may choose to share
                  information. As such, you should be cautious about the access
                  you provide to others when using Counselling Room, and the
                  information you choose to share when using the Counselling
                  Room website or App.
                </p>

                <h5>How to Contact Us</h5>
                <p>
                  If you have any privacy-related questions or comments related
                  to this Policy, please send an email
                  to counsellingroomonline@gmail.com 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
