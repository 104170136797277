import React from "react";
import "./ServicePage.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";

function ServicePage() {
  return (
    <div>
    <Navbar/>
      <div className="join_our_team_wrap">
        <div className="container">
          <div className="row">
            <div className="col l12">
              <h3>Our Services</h3>
              <p className="white-text">
                Counselling room offers a wide range of therapy, group sessions,
                and more… if you have questions about our services, please
                contact us at{" "}
                <a
                  className="white-text"
                  href="mailto:counsellingroom915@gmail.com"
                >
                  counsellingroom915@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginBottom: 30,
        }}
        className="container"
      >
        <div className="col l12">
          <div>
            <h5>Therapy</h5>
            <p>
              THERAPY OR COUNSELLING offers a chance for personal
              transformation, relief from distress and emotional suffering and
              progress towards your goals. Our therapists are here to serve and
              provide necessary help that may be needed for our clients to walk
              through the journey to mental betterment and they are continuously
              training about the latest evidence-based methods to best serve our
              clients. Counselling Room offers individual therapy, couples
              therapy, group therapy, and family therapy services.
            </p>
          </div>

          <div>
            <h5>Individual therapy</h5>
            <p>
              Individual therapy is your chance to work one-on-one with a
              qualified, expert trained counselor or therapist dedicated to
              helping you have the life you deserve. Our therapists and
              psychologists are dedicated to helping you to love yourselves and
              more, and we would be thrilled to join you on your healing
              journey. Check our work with us page to learn more about our
              therapists.
            </p>
          </div>

          <div>
            <h5>Couples therapy</h5>
            <p>
              Couples therapy or counselling is essential for any relationship
              that needs deeper intimacy, strong communication and greater
              trust. Whether your relationship is struggling, or you are looking
              to take your bond to new heights, consider giving couples therapy
              a try with us.
            </p>
          </div>

          <div>
            <h5>Group therapy</h5>
            <p>
              Group therapy is the best option for those who want to understand
              themselves and others on a deeper level. Group is a bit of a
              social sandbox that offers opportunities to explore feelings that
              arise in relationships. As you grow to understand yourself and how
              others see you, these insights will pay off in your connections
              outside of therapy.
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ServicePage;
