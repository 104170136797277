import React from "react";
import Work1 from "../../../Lib/images/work1.png";
import Work2 from "../../../Lib/images/work2.png";
import Work3 from "../../../Lib/images/work3.png";
import "./HowWeWork.css";

function HowWeWork() {
  return (
    <div className="how_we_work_wrap">
      <div className="container">
        <h3 className="center">How Counselling Room works</h3>
        <div className="row">
          <div className="col l4">
            <div className="center item">
              <div>
                <img
                  width={"150px"}
                  className="responsive-img"
                  src={Work1}
                  alt=""
                />
              </div>
              <h5>Brief assessment</h5>
              <p>Answer a few questions about your preferences.</p>
            </div>
          </div>
          <div className="col l4">
            <div className="center item">
              <div>
                <img
                  width={"150px"}
                  className="responsive-img"
                  src={Work2}
                  alt=""
                />
              </div>
              <h5>Personalized match</h5>
              <p>We will match you with a provider as quickly as possible.</p>
            </div>
          </div>
          <div className="col l4">
            <div className="center item">
              <div>
                <img
                  width={"150px"}
                  className="responsive-img"
                  src={Work3}
                  alt=""
                />
              </div>
              <h5>Start therapy</h5>
              <p>Begin the journey towards a better you.</p>
            </div>
          </div>
        </div>

        <p className="center">
          <span>Get Started</span>
        </p>
      </div>
    </div>
  );
}

export default HowWeWork;
