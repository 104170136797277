import React from "react";
import "./Benefits.css";
import BenefitsImg from "../../../Lib/images/benefit.png";
import { GiReceiveMoney, GiPlayerTime } from "react-icons/gi";
import { AiOutlineUserSwitch } from "react-icons/ai";

function Benefits() {
  return (
    <div className="benefit_wrap">
      <div className="container">
        <div className="row">
          <div className="col l5">
            <div>
              <img src={BenefitsImg} alt="" />
            </div>
          </div>
          <div className="col l7 ">
            <div className="right">
              <h3>Benefits of Counselling room </h3>
              <div>
                <p>
                  <span
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <GiPlayerTime color="coral" />
                  </span>{" "}
                  Elimination of commute time and scheduling hassles.
                </p>
                <p>
                  {" "}
                  <span
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <AiOutlineUserSwitch color="coral" />
                  </span>
                  Seamlessly switch provides at no extra cost.
                </p>

                <p>Flexible plans to meet your needs and lifestyle.</p>
                <p>
                  <span
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <GiReceiveMoney color="coral" />
                  </span>{" "}
                  Save money while receiving high quality care.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
