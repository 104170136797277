import React from "react";
import DirectorImg from "../../../Lib/images/director.png";
import { TiTick } from "react-icons/ti";
import "./DirectorMessage.css";

function DirectMessage() {
  return (
    <div className="white director_message_wrapper">
      <div className="container">
        <div className="row">
          <div className="col l4">
            <div>
              <img className="responsive-img" src={DirectorImg} alt="" />
            </div>
          </div>
          <div className="col l7 offset-l1">
            <div>
              <h3>Director's Message</h3>
              <div>
                <p>
                  Our network of providers cover a range of specialities to meet
                  your specific needs. Get matched today!
                </p>
                <div>
                  <div className="row">
                    <div className="col l4">
                      <p>
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Lactation Counselling
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Relationships
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Anxiety & Stress
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Parenting
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Couple Counselling
                      </p>
                    </div>
                    <div className="col l4">
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Chronic illness
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>{" "}
                        Eating disorders .
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Anger management
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>{" "}
                        Childhood abuse
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Mood disorders
                      </p>
                    </div>
                    <div className="col l4">
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>{" "}
                        HIV/Aids Pretesting Counselling
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Trauma & grief
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        Substance abuse
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>{" "}
                        Family conflict{" "}
                      </p>
                      <p>
                        {" "}
                        <span>
                          <TiTick
                            size={20}
                            style={{ paddingTop: 5 }}
                            color="coral"
                          />
                        </span>
                        and more..
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DirectMessage;
