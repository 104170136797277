import React from "react";
import JoinTeamForm from "../../Components/JoinTeamForm/JoinTeamForm";
import "./JoinOurTeamPage.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";

const JoiningData = [
  {
    id: 1,
    title: "Reliable income",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
  {
    id: 2,
    title: "No overhead",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
  {
    id: 3,
    title: "Supportive clinical community",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
  {
    id: 4,
    title: "Mentorship program",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
  {
    id: 5,
    title: "Flexible hours",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
  {
    id: 6,
    title: "Same-week start",
    text: "Choose your caseload, earn monthly income, and become eligible for additional bonuses.",
  },
];

function JoinOurTeamPage() {
  return (
    <div>
    <Navbar/>
      <div className="join_our_team_wrap">
        <div className="container">
          <div className="row">
            <div className="col l12">
              <h3>Join Our Team</h3>
              <p className="white-text">
                Provide behavioral care at your convenience anytime anywhere
                just by connecting with your clients.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <h3 className="center">Reasons To Join Counselling Room</h3>

          <h5>Increased pay </h5>
          <p>
            Make almost double the hourly rate of our competitions for live
            sessions (via video, audio or chat)
          </p>

          <h5>Flexibility</h5>
          <p>Set a schedule that works best for you and your client.</p>
          <h5>Reliable income</h5>
          <p>Choose your caseload, and earn daily or earn monthly income.</p>
          <h5>Continued education time.</h5>
          <p>
            Earn as you’re up grading your papers at the comfort of your home.
          </p>
          {/*JoiningData.map((item) => (
            <SingleJoin key={item.id} title={item.title} text={item.text} />
          ))*/}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div className="requirement_wrap"></div>
        <div>
          <div className="container ">
            <div className="row">
              <h3 className="center">HOW TO GET STARTED </h3>
              <div className="col l12">
                <h5>Submit your application</h5>
                <p>
                  After receive your application, well reach out via an email
                  and a phone call.
                </p>
                <p>
                  Complete on the job training with a mentor about how to use
                  Counselling room.
                </p>
                <h5>Start getting clients</h5>
                <p>
                  Set your caseload and hours, and check in on clients daily, 5
                  days a week.
                </p>
                <h5>Requirements</h5>
                <p>
                  Relevant degree or diploma in guidance and counselling with
                  more than two years of experience in one-on-one counselling in
                  any field in psychology.{" "}
                </p>
                <p>Valid identification card.</p>
                <p>Recommendation letter.</p>
                <p>Students’ identification for internships.</p>
                <p>University referral letter.</p>
                <p>An updated and valid CV. </p>
                <p>
                  Submission of a fully completed, signed application via an
                  email.
                </p>
                <p>A Computer and a smart phone.</p>
                <p>Reliable internet connection</p>


                <p>
      <Link to='/sign_up'>Join Now</Link>
      </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    {/*  <JoinTeamForm />*/}

      <Footer/>
    </div>
  );
}

export default JoinOurTeamPage;

const SingleJoin = ({ title, text }) => {
  return (
    <>
      <div className="col l6">
        <div className="center white single_join_wrap">
          <h5>{title}</h5>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};
