import React from "react";
import "./Footer.css";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <hr />
      <footer
        style={{
          background: "rgb(6, 16, 39)",
        }}
        class="page-footer "
      >
        <div class="container">
          <div class="row">
            <div class="col l6 s12">
              <h5 style={{
                color:"coral"
              }} class="">Counselling Room Online</h5>
              <p class="grey-text text-lighten-4">
                If you are in a life threatening situation - don’t use this
                site. Call +256 777183553 or use these resources to get
                immediate help.
              </p>
            </div>
            <div class="col l4 offset-l2 s12">
              <h5 class="white-text">Links</h5>
              <ul>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    About
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a class="grey-text text-lighten-3" href="#!">
                    Join Us
                  </a>
                </li>
                <li>
                  <Link
                    to="/privacy_policy"
                    class="grey-text text-lighten-3"
                    href="#!"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sign_in"
                    class="grey-text text-lighten-3"
                 
                  >
                  Counsellors Portal
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-copyright">
          <div class="container">
            ©2022 Counselling Room Online
            <a
              class="grey-text text-lighten-4 right"
              target={"_blank"}
              href="https://layouts.levbitz.com/"
            >
              maintained by Levbtz layouts
            </a>
          </div>
        </div>
      </footer>

      <div className="tsup">
        <p>
          <a
            href="https://wa.me/256777183553"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsWhatsapp size={30} color="green" />
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
